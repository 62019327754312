import React from 'react'
import {
    LegacyCard,
    FormLayout,
    TextField,
    Text,
    TextContainer,
} from "@shopify/polaris"
import styled from 'styled-components'
import update from 'immutability-helper'
import ManualRules from './manualRules'
import ShopBrain from '../checkout/shopBrain'
import CartOfferTypeSelector from "../common/cartOfferTypeSelector/cartOfferTypeSelector";
import CalloutBannerCard from "../common/calloutBannerCard/calloutBannerCard";

const CartOfferTypeWrapper = styled.div`
    margin-bottom: 20px;
    .rule-type {
        padding-top: 1.6rem;
        .rule-type-subtitle {
            margin: 0px 0 20px;
        }
        .rule-type-option {
            border: 2px solid #AAA;
            border-radius: 4px;
            padding: 10px 10px;
            margin-bottom: 25px;
            .rule-type-image {
                text-align: center;
                img {
                    width: 85px;
                }
            }
            .rule-type-image:nth-of-type(2) {
                img {
                    width: 96px;
                }
            }
            .rule-type-desp {
                max-width: 210px;
            }
        }
        .active {
            border: 2px solid #008060;
        }
    }
`
function CartOfferType(props) {
  const { host, token, checkoutOfferType, handleCheckoutOfferTypeChange, shop, addSmartRule, state, setState, productPickerShow, setProductPickerShow, error, productEdit, setProductEdit, currency, locale, currencySymbol } = props

  return (
    <CartOfferTypeWrapper>
      <LegacyCard sectioned>
        <FormLayout>
          <Text as="h3" variant="headingSm">Name Your upsell</Text>
          <TextField
                  label="Internal offer nickname"
                  placeholder="Your shoppers won’t see this."
                  labelHidden={true}
                  onChange={(val) => {
                      setState(update(state, { name: { $set: val }}))
                  }}
                  value={state.name}
                  autoComplete="off"
              />
          {state.id ?
            <TextContainer spacing="tight">
              <Text tone="subdued">Offer ID: {state.id}</Text>
            </TextContainer>
            :
            false
        }
        </FormLayout>
        <div style={{marginTop: '20px'}}>
          <CartOfferTypeSelector
            offerType={checkoutOfferType}
            changeOfferType={handleCheckoutOfferTypeChange}
            state={state}
            setState={setState}
          />
        </div>
      </LegacyCard>

      <CalloutBannerCard state={state} setState={setState} fields={['banner', 'introText']}/>

      {"manual"===checkoutOfferType ?
        <ManualRules host={host} shop={shop} token={token} addSmartRule={addSmartRule} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} error={error} productEdit={productEdit} setProductEdit={setProductEdit} currency={currency} currencySymbol={currencySymbol} checkoutOfferType={checkoutOfferType}/>
        :
        <ShopBrain host={host} checkoutOfferType={checkoutOfferType} shop={shop} token={token} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currency={currency} locale={locale} currencySymbol={currencySymbol} addSmartRule={addSmartRule} />
      }
    </CartOfferTypeWrapper>
  )
}

export default CartOfferType;